<template>
  <div class="img-item" @mouseover="itemClick(index)">
    <a-row class="img-row">
      <a-col v-for="item in source.arr" :key="item.img.id" :span="6">
        <div class="card" @click="openImg(item)">
          <div>
            <div class="img-div">
              <img class="back-img" v-if="item.img" :src="item.img.url" style="" alt="" />
              <img class="back-img-b" v-if="item.img" :src="item.img.url" style="" alt="" />
            </div>
            <div class="img-content" style="line-height:30px">
              <div class="img-title" style="padding-top:5px;">
                {{ item.img.title||' ' }}
              </div>
              <div class="img-user">
                <span
                  ><img
                    :src="item.upper.avatar"
                    style="width:25px;height:25px;border-radius: 50%;margin-right:5px"
                    alt=""
                  />{{ item.upper.realname }}</span
                >
                <span style="margin-left:30px">
                  <a-icon @click.stop="like(item)" :class="item.event.good ? 'lick' : ''" type="like" />
                  <span>{{ item.count.good || 0 }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal width="700px" title="查看大图" :visible="visible" @cancel="handleCancel">
      <div class="max-img">
        <img class="back-img" v-if="imgData.img" :src="imgData.img.url" style="" alt="" />
        <img class="back-img-b" v-if="imgData.img" :src="imgData.img.url" style="" alt="" />
      </div>
      <div class="img-content">
        <div style="max-width:100%;line-height:20px;margin-top: 20px;">{{ imgData.img.title }}</div>
        <div class="img-user">
          <span>
            <img :src="imgData.upper && imgData.upper.avatar" style="width:25px;height:25px;border-radius:50%" alt="" />
            {{ imgData.upper && imgData.upper.realname }}
          </span>
          <span style="margin-left:30px">
            <a-icon @click="like(imgData)" type="like" :style="imgData.event.good ? 'color:' + color : ''" />
            <span>{{ imgData.count.good || 0 }}</span>
          </span>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          关闭
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { postAction, getAction, putAction } from '@/api/manage'
export default {
  props: {
    // 每一行的索引,基础props，不用传递
    index: {
      type: Number
    },
    // 每一行的内容,基础props，不用传递
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    current: String,
    itemClick: Function
  },
  data() {
    return {
      imgData: {
        img: {
          url: ''
        },
        count: {
          good: false
        },
        event: {
          good: false
        }
      },
      visible: false,
      color: ''
    }
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    openImg(item) {
      this.visible = true
      this.imgData = item
    },
    async like(item) {
      if (!item.event.good) {
        item.event.good = true
        item.count.good = (item.count.good || 0) - 0 + 1
      } else {
        item.event.good = false
        item.count.good = (item.count.good || 0) - 1
      }
      await putAction('imgCenterEvent/666/' + item.img.id)
    },
    handleCancel() {
      this.visible = false
      this.imgData = {
        img: {
          url: ''
        },
        count: {
          good: false
        },
        event: {
          good: false
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.img-item {
  margin-bottom: 25px;
}
.max-img {
  height: 450px;
  width: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .back-img {
    position: relative;
    z-index: 1;
    height: 100%;
  }
  .back-img-b {
    position: absolute;
    width: 100%;
    filter: blur(15px);
  }
}
.img-div {
  width: 280px;
  height: 210px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .back-img {
    position: relative;
    z-index: 1;
    height: 100%;
  }
  .back-img-b {
    position: absolute;
    width: 100%;
    filter: blur(15px);
  }
}
.img-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lick {
  color: var(--theme-color);
}
.img-row {
  height: 300px;
  padding-top: 35px;
}
.anticon-like {
  position: relative;
  top: -1px;
  margin-right: 5px;
  cursor: pointer;
}
.card {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
  border-radius: 10px;
  padding-top: 15px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0.8rem;
  }
  > div {
    width: 280px;
  }
}
.ant-col {
  display: flex;
  justify-content: center;
  padding: 0px 20px 0px 20px;
}
.img-content {
  /*   display: flex;
  justify-content: space-between; */
  line-height: 60px;
  .img-user {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }
}
</style>
