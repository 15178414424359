<template>
  <div class="top-button-list" ref="topButtonList" @mousewheel="handleMouseWheel">
    <div @mouseover="topCenterMouseover">
      <div
        v-if="taskDetail.stageName == '翻译' || taskDetail.stageName == '校对'"
        class="top-item"
        @click="confirm"
        @mouseover="confirmColor = color"
        @mouseout="confirmColor = '#4a4a4a'"
      >
        <a-tooltip v-if="taskConfirmStatus">
          <template slot="title">
            取消确认
          </template>
          <!-- <file-success theme="outline" size="19" :fill="confirmColor" :strokeWidth="4" /> -->
          <file-failed theme="outline" size="19" :fill="confirmColor" :strokeWidth="4" />
          <div class="top-item-hover" style="top:12px;"></div>
        </a-tooltip>
        <a-tooltip v-else>
          <template slot="title">
            确认
          </template>
          <file-success theme="outline" size="19" :fill="confirmColor" :strokeWidth="4" />
          <div class="top-item-hover" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <div class="top-item" :style="isAutoSave ? '' : 'cursor: no-drop;'">
        <!-- <a-icon type="save" /> -->
        <a-tooltip>
          <template slot="title">
            保存 CTRL+S
          </template>
          <save
            theme="outline"
            size="20"
            style="position:relative;top:2px;"
            :fill="isAutoSave && changeAll ? color : saveColor"
            :strokeWidth="4"
          />
          <div class="top-item-hover" style="top:12px;" @click="changeAutoSave"></div>
        </a-tooltip>
      </div>
      <div
        class="top-item downLoad-button"
        v-if="checkPermission('translate:download')"
        @mouseover="changedownLoadColor"
        @mouseout="changedownLoadColorOut"
      >
        <!-- 显示逻辑修改成权限控制,陈立说的 -->
        <!-- <div class="top-item-hover"></div> -->
        <!--         <a-tooltip>
          <template slot="title">
            下载翻译JPG
          </template>
          <down-picture theme="outline" size="20" style='position:relative;top:2px;' :fill="downLoadColor" :strokeWidth="3" />
          <div class="top-item-hover" style='top:12px;'></div>
        </a-tooltip> -->
        <a-tooltip @mouseover.stop @mouseout.stop>
          <template slot="title">
            <span>导出</span>
          </template>
          <a-dropdown :trigger="['click']">
            <div style="position:relative;top:2px;width:40px">
              <down-picture theme="outline" size="20" :fill="downLoadColor" :strokeWidth="3" /><a-icon
                type="down"
                style="font-size:12px;position: relative;top: -3px;right: -4px;"
              />
              <div class="top-item-hover" style="top:-4px;width:46px;"></div>
            </div>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="downLoadImg('JPG')">下载翻译JPG </a-menu-item>
              <a-menu-item key="2" @click="downLoadImg('PDF')">导出PDF </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-tooltip>
      </div>
      <div class="top-item" @click="backData" @mouseover="undoColor = color" @mouseout="undoColor = '#4a4a4a'">
        <a-tooltip>
          <template slot="title">
            撤回 CTRL+Z
          </template>
          <undo theme="outline" size="19" style="position:relative;top:2px;" :fill="undoColor" :strokeWidth="4" />
          <div class="top-item-hover" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <div class="top-item" @click="nextData" @mouseover="redoColor = color" @mouseout="redoColor = '#4a4a4a'">
        <a-tooltip>
          <template slot="title">
            重做 CTRL+Y
          </template>
          <redo theme="outline" size="19" style="position:relative;top:2px;" :fill="redoColor" :strokeWidth="4" />
          <div class="top-item-hover" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <div class="top-slider top-item top-item-left" style="margin-right:24px">
        <a-slider
          style="position:relative;top:-1px;"
          transitionName=""
          :default-value="100"
          :value="sliderValue"
          :max="100"
          :min="10"
          @change="sliderHandledChange"
        />
      </div>
      <div
        v-if="checkPermission('rotation-none:view')"
        class="top-item"
        @mouseover="color1 = color"
        @mouseout="color1 = '#4a4a4a'"
      >
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            横向
          </template>
          <text-rotation-none
            style="position:relative;top:2px;"
            theme="outline"
            size="18"
            :fill="textBatchStyle.textLayout == 1 ? color : color1"
            :strokeWidth="4"
          />
          <div
            class="top-item-hover"
            style="top:12px;"
            @click="checkPermission('rotation-none:edit') ? textLayoutBatchChange(1) : () => {}"
          ></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('rotation-down:view')"
        class="top-item"
        style="margin-right:24px"
        @mouseover="color2 = color"
        @mouseout="color2 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            竖向
          </template>
          <text-rotation-down
            style="position:relative;top:2px;"
            theme="outline"
            size="18"
            :fill="textBatchStyle.textLayout == 2 ? color : color2"
            :strokeWidth="4"
          />
          <div
            class="top-item-hover"
            style="top:12px;"
            @click="checkPermission('rotation-down:edit') ? textLayoutBatchChange(2) : () => {}"
          ></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('textStyle:view')"
        class="top-item top-item-center"
        style="margin-right:24px;padding-top:2px;"
      >
        <div class="top-item-border"></div>
        <a-tooltip style="position: relative;top: 1px;">
          <template slot="title">
            对齐方式
          </template>
          <div
            class="top-item-hover-left"
            @click="checkPermission('textStyle:edit') ? textAlignChange('left') : () => {}"
          ></div>
          <div
            class="top-item-hover-center"
            @click="checkPermission('textStyle:edit') ? textAlignChange('center') : () => {}"
          ></div>
          <div
            class="top-item-hover-right"
            @click="checkPermission('textStyle:edit') ? textAlignChange('right') : () => {}"
          ></div>
          <div style="width:82px">
            <align-text-left
              @mouseover="color11 = color"
              @mouseout="color11 = '#4a4a4a'"
              :style="{ marginRight: '12px' }"
              theme="outline"
              size="19"
              :fill="textBatchStyle.textAlign == 'left' ? color : color11"
              :strokeWidth="4"
            />
            <align-text-center
              @mouseover="color12 = color"
              @mouseout="color12 = '#4a4a4a'"
              :style="{ marginRight: '12px' }"
              theme="outline"
              size="19"
              :fill="textBatchStyle.textAlign == 'center' ? color : color12"
              :strokeWidth="4"
            />
            <align-text-right
              @mouseover="color13 = color"
              @mouseout="color13 = '#4a4a4a'"
              theme="outline"
              size="19"
              :fill="textBatchStyle.textAlign == 'right' ? color : color13"
              :strokeWidth="4"
            />
          </div>
        </a-tooltip>
      </div>
      <div v-if="checkPermission('textStyle:view')" class="top-item">
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            字体
          </template>
          <a-select
            placeholder="请选择字体"
            v-if="
              selectTextAreaData.every(item =>
                item.characterSentences.every(
                  text => text.fontFamily == selectTextAreaData[0].characterSentences[0].fontFamily
                )
              )
            "
            :disabled="!checkPermission('textStyle:edit')"
            :defualt-value="textBatchStyle.fontFamilyId"
            v-model="textBatchStyle.fontFamilyId"
            style="width: 120px;font-size:14px;position:relative;top:1px;"
            @change="textFamilyBatchChange"
          >
            <a-select-option
              v-if="
                textBatchStyle.fontFamilyId &&
                  fontFamilyList
                    .filter(
                      font =>
                        (defualtTextType.fontFamilys && defualtTextType.fontFamilys.some(item => item.id == font.id)) ||
                        globalSetting
                    )
                    .findIndex(item => item.id == textBatchStyle.fontFamilyId) == -1
              "
              @mouseover="topCenterMouseover"
              :value="textBatchStyle.fontFamilyId"
            >
              自定义字体
            </a-select-option>
            <a-select-option
              :value="item.id"
              @mouseover="topCenterMouseover"
              v-for="item in fontFamilyList.filter(
                font =>
                  (defualtTextType.fontFamilys && defualtTextType.fontFamilys.some(item => item.id == font.id)) ||
                  globalSetting
              )"
              :key="item.id"
            >
              {{ item.displayName }}
            </a-select-option>
          </a-select>
          <a-select
            v-else
            :disabled="!checkPermission('textStyle:edit')"
            :value="'-'"
            placeholder="请选择字体"
            @change="disFontFamilyChange"
            style="width: 80px;"
          >
            <a-select-option @mouseover="topCenterMouseover" :value="'-'" :key="'-'">-</a-select-option>
            <a-select-option
              :value="item.id"
              v-for="item in fontFamilyList.filter(
                font => defualtTextType.fontFamilys && defualtTextType.fontFamilys.some(item => item.id == font.id)
              )"
              @mouseover="topCenterMouseover"
              :key="item.id"
            >
              {{ item.displayName }}
            </a-select-option>
          </a-select>
        </a-tooltip>
      </div>
      <div class="top-item-text" v-if="checkPermission('textStyle:view')">
        <!-- <a-select
          :defualt-value="textBatchStyle.styleName"
          v-model="textBatchStyle.styleName"
          :disabled="true"
          @change="textStyleNameBatchChange"
          style="width:100px"
        >
          <a-select-option :value="item" v-for="item in getFontStyleNames(fontFamilyList)" :key="item">
            {{ item }}
          </a-select-option>
        </a-select> -->
        <a-tooltip>
          <template slot="title">
            字体样式
          </template>
          <span style="font-size:14px;position:relative;top:-1px;">{{ textBatchStyle.styleName || 'Regular' }}</span>
        </a-tooltip>
      </div>
      <div class="top-item" v-if="checkPermission('textStyle:view')" style="margin-right:24px">
        <!-- <div class="top-item-hover" style='left:-7px;z-index:101;width:83px'></div> -->
        <a-tooltip>
          <template slot="title">
            字号
          </template>
          <a-select
            v-if="
              selectTextAreaData.every(item =>
                item.characterSentences.every(
                  text => text.fontSize == selectTextAreaData[0].characterSentences[0].fontSize
                )
              ) || phoneticTexts.length
            "
            :filter-option="filterOption"
            show-search
            @search="fontChange"
            placeholder="请选择字号"
            :disabled="!checkPermission('textStyle:edit')"
            v-model="textBatchStyle.fontSize"
            @change="textFontSizeBatchStyle"
            :dropdownMenuStyle="{ maxHeight: '450px !important' }"
            style="width: 70px;font-size:14px;position: relative;top: 2px;"
          >
            <a-select-option
              @mouseover="topCenterMouseover"
              :value="item.value"
              v-for="item in fontSizes"
              :key="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-select
            v-else
            :disabled="!checkPermission('textStyle:edit')"
            :value="'-'"
            placeholder="请选择字号"
            @change="disFontChange"
            style="width: 70px;font-size:14px;"
          >
            <a-select-option :value="'-'" :key="'-'">-</a-select-option>
            <a-select-option :value="item.value" v-for="item in fontSizes" :key="item.label">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-tooltip>
      </div>
      <div class="top-item top-item-center" v-if="checkPermission('textStyle:view')">
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            加粗
          </template>
          <a-icon
            type="bold"
            @click="boldChange"
            :style="
              textBatchStyle.isBold ? { color, position: 'relative', top: '2px' } : { position: 'relative', top: '2px' }
            "
          />
          <div class="top-item-hover" style="left:-7px;top:12px" @click="boldChange"></div>
        </a-tooltip>
      </div>
      <div class="top-item top-item-center" v-if="checkPermission('textStyle:view')">
        <a-tooltip>
          <template slot="title">
            斜体
          </template>
          <a-icon
            type="italic"
            :style="
              textBatchStyle.isIncline
                ? { color, position: 'relative', top: '2px' }
                : { position: 'relative', top: '2px' }
            "
          />
          <div class="top-item-hover" @click="isInclineChange" style="top:12px"></div>
        </a-tooltip>
      </div>
      <div
        class="top-item top-item-center"
        style="margin-right:24px;margin-left:2px;"
        v-if="checkPermission('textStyle:view')"
      >
        <a-tooltip>
          <template slot="title">
            字体颜色
          </template>
          <div class="color-change" @click="checkPermission('textStyle:edit') ? colorClick('FontColor') : () => {}">
            <div>
              <div class="color-change-text">
                <div class="top-item-hover" style="left:-9px"></div>
                A
              </div>
              <div
                class="color-change-bottom"
                :style="{ background: textBatchStyle.fontColor, border: '1px solid rgba(0,0,0,0.25)' }"
              ></div>
            </div>
          </div>
          <colorPicker
            ref="colorPickerFontColor"
            :defaultColor="textBatchStyle.fontColor"
            @change="textBatchColorChange"
            v-model="textBatchStyle.fontColor"
          />
        </a-tooltip>
      </div>
      <div class="top-item top-item-center" v-if="checkPermission('textStyle:view')">
        <div class="top-item-border" style="left:-14px"></div>
        <a-tooltip>
          <template slot="title">
            字体描边
          </template>
          <div class="color-change" @click="checkPermission('textStyle:edit') ? colorClick('FontStroke') : () => {}">
            <div>
              <div
                class="color-change-stroke-text"
                :style="`height:19px;line-height:18px;-webkit-text-stroke: 1px rgba(0,0,0,0.65)`"
              >
                <div class="top-item-hover" style="left:-9px"></div>
                A
              </div>
              <div
                class="color-change-bottom-stroke"
                :style="{ background: textBatchStyle.strokeColor, border: '1px solid rgba(0,0,0,0.25)' }"
              ></div>
            </div>
          </div>
          <colorPicker
            ref="colorPickerFontStroke"
            :defaultColor="textBatchStyle.strokeColor"
            @change="textStrokeColorBatchChange"
            v-model="textBatchStyle.strokeColor"
          />
        </a-tooltip>
      </div>
      <div class="top-item" v-if="checkPermission('textStyle:view')">
        <!-- <div class="top-item-hover"></div> -->
        <a-tooltip>
          <template slot="title">
            描边宽度
          </template>
          <a-select
            style="width: 70px;font-size:14px;"
            v-if="
              selectTextAreaData.every(item =>
                item.characterSentences.every(
                  text => text.strokeWidth == selectTextAreaData[0].characterSentences[0].strokeWidth
                )
              ) || phoneticTexts.length
            "
            :disabled="!checkPermission('textStyle:edit')"
            @change="textStrokeWidthBatchChange"
            show-search
            v-model="textBatchStyle.strokeWidth"
            placeholder="请选择描边宽度"
          >
            <a-select-option @mouseover="topCenterMouseover" v-for="i in strokeWidths" :key="i">
              {{ i }}
            </a-select-option>
          </a-select>
          <a-select
            v-else
            :disabled="checkPermission('textStyle:edit')"
            :value="'-'"
            placeholder="请选择描边宽度"
            @change="disStrokeChange"
            style="width: 70px;font-size:14px;"
          >
            <a-select-option :value="'-'" :key="'-'">-</a-select-option>
            <a-select-option v-for="i in strokeWidths" :key="i">
              {{ i }}
            </a-select-option>
          </a-select>
        </a-tooltip>
      </div>
      <div class="top-item top-item-right" style="margin-right:32px" v-if="checkPermission('background:view')">
        <a-tooltip>
          <template slot="title">
            文本框背景颜色
          </template>
          <div class="color-change" @click="checkPermission('background:edit') ? colorClick('Back') : () => {}">
            <div>
              <div class="color-change-back">
                <!-- <fill theme="outline" size="19" :fill="color4" :strokeWidth="3" /> -->
                <div class="top-item-hover" style="left:-1px"></div>
                <span
                  class="iconfont icon-youqitong icon"
                  style="font-size:18px;position:relative;top:-4px;left:5px;"
                ></span>
              </div>
              <div class="color-change-bottom-back" :style="{ background: textBatchStyle.backgroundColor }"></div>
            </div>
          </div>
          <colorPicker
            ref="colorPickerBack"
            :defaultColor="textBatchStyle.backgroundColor"
            @change="textBackBatchChange"
            v-model="textBatchStyle.backgroundColor"
          />
        </a-tooltip>
      </div>
      <div
        class="top-item top-item-right"
        style="margin-right:12px"
        v-if="taskDetail.targetLang != 'JAPANESE' && checkPermission('zhuozhonghao:view')"
      >
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            着重号
          </template>
          <span
            class="iconfont icon-zhuozhonghao icon"
            :style="
              textBatchStyle.hasMark
                ? { color, position: 'relative', top: '2px' }
                : { position: 'relative', top: '2px' }
            "
          ></span>
          <div class="top-item-hover" @click="markAllText" style="top:12px"></div>
        </a-tooltip>
      </div>
      <div
        class="top-item top-item-right"
        style="margin-right:10px"
        v-if="taskDetail.targetLang == 'JAPANESE' && checkPermission('zhuyin:view')"
      >
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            注音
          </template>
          <span class="iconfont icon-pinyin icon" style="font-size:20px;position:relative;top:2px;"></span>
          <div class="top-item-hover" style="top:12px" @click="openPhonetic"></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('symbol:view')"
        class="top-item top-item-right"
        style="margin-right:10px"
        @mouseover="color10 = color"
        @mouseout="color10 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            特殊字符
          </template>
          <pound theme="outline" size="21" style="position:relative;top:2px;" :fill="color10" :strokeWidth="3" />
          <div class="top-item-hover" style="top:12px" @click="openEmj"></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('jiajv:view')"
        class="top-item top-item-right"
        @mouseover="color3 = color"
        @mouseout="color3 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            佳句
          </template>
          <thumbs-up theme="outline" size="21" :fill="color3" style="position:relative;top:2px;" :strokeWidth="3" />
          <div class="top-item-hover" style="top:12px" @click="addSentence"></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('addTextArea:view')"
        class="top-item"
        style="margin-left:11px"
        @mouseover="color5 = color"
        @mouseout="color5 = '#4a4a4a'"
      >
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            添加文本框
          </template>
          <doc-add
            theme="outline"
            size="21"
            style="position:relative;top:2px;"
            :fill="isAddText ? color : color5"
            :strokeWidth="3"
          />
          <div class="top-item-hover" @click="changeIsAddText" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <div v-if="checkPermission('addLine:view')" class="top-item" style="margin-left:11px" @click="changeIsAddLine">
        <div class="top-item-border"></div>
        <a-tooltip>
          <template slot="title">
            添加连线
          </template>
          <a-icon :class="isAddLine ? 'select-icon' : ''" type="arrows-alt" />
          <div class="top-item-hover" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <!--       <div
        v-if="checkPermission('translateOcr:view')"
        class="top-item"
        @mouseover="color16 = color"
        @mouseout="color16 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            文字识别
          </template>
          <text-recognition
            style="position:relative;top:2px;"
            theme="outline"
            size="21"
            :fill="color16"
            :strokeWidth="3"
          />
          <div class="top-item-hover" @click="ocr" style="top:12px;"></div>
        </a-tooltip>
      </div> -->
      <div
        v-if="checkPermission('addAnnotation:view')"
        class="top-item"
        @mouseover="color6 = color"
        @mouseout="color6 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            添加批注
          </template>
          <local-two
            theme="outline"
            @click="changeIsAddAnnotation"
            style="position:relative;top:2px;"
            size="21"
            :fill="isAddAnnotation ? color : color6"
            :strokeWidth="3"
          />
          <div class="top-item-hover" @click="changeIsAddAnnotation" style="top:12px;"></div>
        </a-tooltip>
      </div>
      <!--       <div
        v-if="checkPermission('search:view')"
        class="top-item"
        @mouseover="color7 = color"
        @mouseout="color7 = '#4a4a4a'"
      >
        <a-tooltip>
          <template slot="title">
            搜索
          </template>
          <search theme="outline" style="position:relative;top:2px;" size="21" :fill="color7" :strokeWidth="3" />
          <div class="top-item-hover" @click="openSearch" style="top:12px;"></div>
        </a-tooltip>
      </div> -->
      <div
        v-if="checkPermission('note:add') && departName.indexOf('韩语部') == -1"
        class="top-item"
        @mouseover="color7 = color"
        @mouseout="color7 = '#4a4a4a'"
        style="margin-right:24px;"
      >
        <a-tooltip transitionName="">
          <template slot="title">
            表记
          </template>
          <form-one theme="outline" style="position:relative;top:2px;" size="21" :fill="color7" :strokeWidth="3" />
          <div class="down-menu">
            <a-dropdown transitionName="" :trigger="['click']" placement="bottomCenter">
              <a-menu slot="overlay">
                <a-menu-item key="1" @mouseover="topCenterMouseover" @click="openNote">添加表记</a-menu-item>
                <a-menu-item key="2" @mouseover="topCenterMouseover" @click="goToTerm(4)">查看表记</a-menu-item>
              </a-menu>
              <a-icon type="down" @click.stop />
            </a-dropdown>
          </div>
          <div class="top-item-hover" @click="openNote" style="top:12px;width:44px;"></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('terms:view')"
        class="top-item"
        @mouseover="color8 = color"
        @mouseout="color8 = '#4a4a4a'"
        style="margin-right:24px;"
      >
        <a-tooltip>
          <template slot="title">
            术语表
          </template>
          <read-book theme="outline" style="position:relative;top:2px;" size="21" :fill="color8" :strokeWidth="3" />
          <div class="down-menu">
            <a-dropdown transitionName="" :trigger="['click']" placement="bottomCenter">
              <a-menu slot="overlay">
                <a-menu-item key="1" @mouseover="topCenterMouseover" @click="addTerm">添加术语表</a-menu-item>
                <a-menu-item key="2" @mouseover="topCenterMouseover" @click="goToTerm(1)">查看术语表</a-menu-item>
                <a-menu-item key="3" @mouseover="topCenterMouseover" @click="goToTerm(3)">查看关系图</a-menu-item>
              </a-menu>
              <a-icon type="down" @click.stop />
            </a-dropdown>
          </div>
          <div class="top-item-hover" @click="addTerm" style="top:12px;width:44px;"></div>
        </a-tooltip>
      </div>
      <div
        v-if="checkPermission('termsBack:view')"
        class="top-item"
        @mouseover="color9 = color"
        @mouseout="color9 = '#4a4a4a'"
      >
        <!-- <div class="top-item-hover"></div> -->
        <a-tooltip>
          <template slot="title">
            背景册
          </template>
          <document-folder
            theme="outline"
            style="position:relative;top:2px;"
            size="21"
            :fill="color9"
            :strokeWidth="3"
          />
          <div class="down-menu">
            <a-dropdown transitionName="" :trigger="['click']" placement="bottomCenter">
              <a-menu slot="overlay">
                <a-menu-item key="1" @mouseover="topCenterMouseover" @click="addTermBack">添加背景册</a-menu-item>
                <a-menu-item key="2" @mouseover="topCenterMouseover" @click="goToTerm(2)">查看背景册</a-menu-item>
              </a-menu>
              <a-icon type="down" @click.stop />
            </a-dropdown>
          </div>
          <div class="top-item-hover" @click="addTermBack" style="top:12px;width:44px;"></div>
        </a-tooltip>
      </div>
      <!--       <div
        v-if="checkPermission('setting:view')"
        class="top-item"
        @mouseover="color14 = color"
        @mouseout="color14 = '#4a4a4a'"
      >
        <div class="top-item-hover"></div>
        <a-popover transitionName="" title="文本框卡片显示方式" trigger="click">
          <template slot="content">
            <a-radio-group v-model="blackListMode" @change="blackListModeChange">
              <a-radio :value="1">
                固定
              </a-radio>
              <a-radio :value="2">
                卡片
              </a-radio>
            </a-radio-group>
          </template>
          <setting-two style="position:relative;z-index:1" theme="outline" size="21" :fill="color14" :strokeWidth="3" />
        </a-popover>
      </div> -->
      <!-- <div class="top-item" :style="copyContent ? '' : 'cursor: no-drop;'">
        <a-tooltip>
          <template slot="title">
            清空剪切板
          </template>
          <clipboard
            theme="outline"
            style="position:relative;top:2px;"
            size="20"
            :fill="copyContent ? color : color15"
            :strokeWidth="3"
          />
          <div class="top-item-hover" @click="clearCopyContent" style="top:12px;"></div>
        </a-tooltip>
      </div> -->
    </div>
  </div>
</template>

<script>
import { checkPermission } from './../utils/hasPermission'
import {
  TextRotationNone,
  TextRotationDown,
  TextBold,
  TextItalic,
  AlignTextLeft,
  AlignTextCenter,
  AlignTextRight,
  ThumbsUp,
  DocumentFolder,
  FileSuccess,
  FileFailed,
  BackgroundColor,
  Fill,
  Undo,
  Redo,
  Save,
  DocAdd,
  Search,
  LocalTwo,
  Pound,
  FormOne,
  TextRecognition,
  DownPicture,
  SettingTwo,
  ReadBook,
  Clipboard
} from '@icon-park/vue'
import { postAction } from '../../../api/manage'
export default {
  components: {
    FileSuccess,
    FileFailed,
    DownPicture,
    SettingTwo,
    FormOne,
    ReadBook,
    DocumentFolder,
    LocalTwo,
    Search,
    Pound,
    DocAdd,
    Fill,
    ThumbsUp,
    BackgroundColor,
    AlignTextLeft,
    AlignTextCenter,
    AlignTextRight,
    TextRotationNone,
    TextRotationDown,
    TextBold,
    TextItalic,
    TextRecognition,
    Undo,
    Redo,
    Save,
    Clipboard
  },
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    authList: {
      type: Array,
      default: () => []
    },
    departName: {
      type: String,
      default: '日语部'
    },
    copyContent: {
      type: String,
      default: ''
    },
    isAddText: {
      type: Boolean,
      default: false
    },
    isAddLine: {
      type: Boolean,
      default: false
    },
    isAutoSave: {
      type: Boolean,
      default: false
    },
    isAddAnnotation: {
      type: Boolean,
      default: false
    },
    taskConfirmStatus: {
      type: Boolean,
      default: false
    },
    globalSetting: {
      type: Boolean,
      default: false
    },
    isRejectStatus: {
      type: Boolean,
      default: false
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    fontFamilyList: {
      type: Array,
      default: () => []
    },
    defualtTextType: {
      type: Object,
      default: () => ({})
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    phoneticTexts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sliderValue: 100,
      fontColor: '#000000',
      saveColor: 'rgba(0, 0, 0, 0.25)',
      downLoadColor: '#4a4a4a',
      confirmColor: '#4a4a4a',
      undoColor: '#4a4a4a',
      redoColor: '#4a4a4a',
      color1: '#4a4a4a',
      color2: '#4a4a4a',
      color3: '#4a4a4a',
      color4: '#4a4a4a',
      color5: '#4a4a4a',
      color6: '#4a4a4a',
      color7: '#4a4a4a',
      color8: '#4a4a4a',
      color9: '#4a4a4a',
      color10: '#4a4a4a',
      color11: '#4a4a4a',
      color12: '#4a4a4a',
      color13: '#4a4a4a',
      color14: '#4a4a4a',
      color15: 'rgba(0, 0, 0, 0.25)',
      color16: '#4a4a4a',
      strokeWidths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40],
      fontSizes: [
        {
          label: 4,
          value: 17
        },
        {
          label: 5,
          value: 21
        },
        {
          label: 6,
          value: 25
        },
        {
          label: 7,
          value: 29
        },
        {
          label: 7.5,
          value: 31
        },
        {
          label: 8,
          value: 33
        },
        {
          label: 8.5,
          value: 35
        },
        {
          label: 9,
          value: 37
        },
        {
          label: 9.5,
          value: 39
        },
        {
          label: 10,
          value: 41
        },
        {
          label: 11,
          value: 45
        },
        {
          label: 12,
          value: 49
        },
        {
          label: 14,
          value: 57
        },
        {
          label: 18,
          value: 73
        },
        {
          label: 24,
          value: 97
        },
        {
          label: 30,
          value: 121
        },
        {
          label: 36,
          value: 145
        },
        {
          label: 48,
          value: 197
        },
        {
          label: 60,
          value: 241
        },
        {
          label: 72,
          value: 289
        }
      ],
      blackListMode: 1,
      textBatchStyle: {
        baselineShift: 0,
        fontColor: '#000000',
        strokeColor: 'rgb(0, 0, 0)',
        strokeWidth: 0,
        fontFamily: null,
        fontSize: 33,
        letterSpacing: 0,
        lineSpacing: 1.15,
        outlineColor: '#fff',
        backgroundColor: '#fff',
        hasMark: 0,
        outlineWidth: 0,
        scaleX: 1,
        scaleY: 1,
        isBold: 0,
        isIncline: 0,
        textAlign: 'left',
        textHeight: 1,
        textWidth: 1,
        textLayout: 1,
        styleName: 'Regular',
        fontFamilyId: undefined
      },
      phoneticStyle: {
        fontColor: '#000000',
        fontSize: 17,
        lineHeight: 1.15,
        lineSpacing: 1.15,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left'
      },
      oldTextBatchStyle: {
        baselineShift: 0,
        fontColor: '#000000',
        strokeColor: '#000000',
        backgroundColor: '#fff',
        strokeWidth: 0,
        fontFamily: null,
        hasMark: 0,
        fontSize: 33,
        letterSpacing: 0,
        isBold: 0,
        isIncline: 0,
        lineSpacing: 1.15,
        textHeight: 1,
        textWidth: 1,
        outlineColor: '#ffffff',
        outlineWidth: 0,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left',
        textLayout: 1,
        styleName: 'Regular',
        fontFamilyId: undefined
      }
    }
  },
  methods: {
    async confirm() {
      if (this.taskConfirmStatus) {
        const res = await postAction(`/task/confirm`, [{ chapterId: this.taskDetail.chapterId, confirm: false }])
        if (res.code == 200) {
          this.$message.success('已取消')
          this.$emit('changeTaskConfirmStatus', false)
        } else {
          this.$message.error(res.msg)
        }
      } else {
        const res = await postAction(`/task/confirm`, [{ chapterId: this.taskDetail.chapterId, confirm: true }])
        if (res.code == 200) {
          this.$message.success('已确认')
          this.$emit('changeTaskConfirmStatus', true)
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    goToTerm(type) {
      window.open(
        `/production/manage/detail/${this.taskDetail.productionId}?&name=${this.taskDetail.productionName}&tab=6&currentType=${type}`
      )
    },
    ocr() {
      /*       if(this.selectTextAreaData.length>1){
        return this.$message.warning('请选中单个文本框')
      } */
      if (!this.selectTextAreaData.length) {
        return this.$message.warning('请选中文本框')
      }
      if (!this.changeAll) return
      this.selectTextAreaData.forEach(textArea => {
        this.$emit('ocr', textArea)
      })
    },
    changedownLoadColor() {
      if (!this.changeAll) return
      /*       setTimeout(() => {
        this.downLoadColor = this.color
      }, 300) */
      this.downLoadColor = this.color
    },
    changedownLoadColorOut() {
      if (!this.changeAll) return
      /*       setTimeout(() => {
        this.downLoadColor = '#4a4a4a'
      }, 300) */
      this.downLoadColor = '#4a4a4a'
    },
    downLoadImg(type) {
      /* if (!this.changeAll) return */
      this.$emit('downLoadImg', type)
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    changeAutoSave() {
      this.$emit('changeAutoSave')
    },
    changeIsAddText() {
      if (!this.changeAll) return
      if (!this.checkPermission('addTextArea:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('changeIsAddTwo')
      this.$emit('changeIsAddText')
    },
    changeIsAddLine() {
      if (!this.changeAll) return
      if (!this.checkPermission('addTextArea:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('changeIsAddLine')
    },
    changeIsAddAnnotation() {
      if (!this.changeAll) return
      if (!this.checkPermission('addAnnotation:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('changeIsAddAnnotation')
    },
    addSentence() {
      if (!this.changeAll) return
      if (!this.checkPermission('jiajv:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('addSentence')
    },
    openEmj() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('symbol:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('openEmj')
    },
    openSearch() {
      if (!this.changeAll) return
      if (!this.checkPermission('search:view')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('openSearch')
    },
    openNote() {
      if (!this.changeAll) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('openNote')
    },
    addTerm() {
      /* if (!this.changeAll) return */
      if (!this.checkPermission('terms:view')) return
/*       if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return */
      this.$emit('addTerm')
    },
    addTermBack() {
      /* if (!this.changeAll) return */
      if (!this.checkPermission('termsBack:view')) return
/*       if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return */
      this.$emit('addTermBack')
    },
    handleMouseWheel(event) {
      if (!this.changeAll) return
      const delta = event.deltaY || event.wheelDelta
      const direction = delta > 0 ? 1 : -1
      this.$refs.topButtonList.scrollLeft += direction * 50
    },
    backData() {
      if (!this.changeAll) return
      this.$emit('backData')
    },
    nextData() {
      if (!this.changeAll) return
      this.$emit('nextData')
    },
    disFontChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.fontSize = value
      this.$emit('textBatchChange', { fontSize: this.textBatchStyle.fontSize })
    },
    textFontSizeBatchStyle(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.fontSize = value
      this.$emit('textBatchChange', { fontSize: this.textBatchStyle.fontSize })
      this.$emit('changeFontSize')
    },
    fontChange(e) {
      if (!this.changeAll) return
      if (e > 0 == false) return
      if (e.indexOf('.') > -1 && e.split('.')[1].length > 1) return
      if (this.fontSizes.every(item => item.label != e)) {
        this.fontSizes.push({ value: e * 4 + 1, label: e })
        this.fontSizes = this.fontSizes.sort((a, b) => a.label - b.label)
      }
    },
    textAlignChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.textAlign = value
      this.$emit('textBatchChange', { textAlign: this.textBatchStyle.textAlign })
    },
    disStrokeChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.strokeWidth = value
      this.$emit('textBatchChange', { strokeWidth: this.textBatchStyle.strokeWidth })
    },
    disFontFamilyChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.fontFamilyId = value
      this.textBatchStyle.fontFamily = this.fontFamilyList.find(item => item.id == value).fontFamily
      this.textBatchStyle.styleName = this.fontFamilyList.find(item => item.id == value).styleName
      this.$emit('textBatchChange', { fontFamily: this.textBatchStyle.fontFamily })
    },
    openPhonetic() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('zhuyin:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('openPhonetic')
    },
    markAllText() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('zhuozhonghao:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      if (this.textBatchStyle.hasMark) {
        this.textBatchStyle.hasMark = 0
      } else {
        this.textBatchStyle.hasMark = 1
      }
      this.$emit('textBatchChange', { hasMark: this.textBatchStyle.hasMark })
    },
    getFontStyleNames() {
      if (!this.changeAll) return
      if (this.textBatchStyle.fontFamilyId) {
        let displayName = this.fontFamilyList.find(item => item.id == this.textBatchStyle.fontFamilyId).displayName
        let arr = this.fontFamilyList
          .filter(
            font =>
              this.defualtTextType.fontFamilys.some(item => item.id == font.id) &&
              (font.displayName.indexOf(displayName) > -1 || displayName.indexOf(font.displayName) > -1)
          )
          .map(item => item.styleName)
        let newArr = [...new Set(arr)]
        return newArr
      } else {
        return []
      }
    },
    blackListModeChange(e) {
      if (!this.changeAll) return
      this.$emit('blackListModeChange', e.target.value)
    },
    boldChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('textStyle:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      if (this.textBatchStyle.isBold) {
        this.textBatchStyle.isBold = 0
      } else {
        this.textBatchStyle.isBold = 1
      }
      this.$emit('changeEditType', 'isBold')
      this.$emit('textBatchChange', { isBold: this.textBatchStyle.isBold })
    },
    isInclineChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('textStyle:edit')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      if (this.textBatchStyle.isIncline) {
        this.textBatchStyle.isIncline = 0
      } else {
        this.textBatchStyle.isIncline = 1
      }
      this.$emit('changeEditType', 'isIncline')
      this.$emit('textBatchChange', { isIncline: this.textBatchStyle.isIncline })
    },
    textFamilyBatchChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.fontFamily = this.fontFamilyList.find(item => item.id == value).fontFamily
      this.textBatchStyle.styleName = this.fontFamilyList.find(item => item.id == value).styleName
      this.$emit('textBatchChange', { fontFamily: this.textBatchStyle.fontFamily })
    },
    textStyleNameBatchChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      let displayName = this.fontFamilyList.find(item => item.id == this.textBatchStyle.fontFamilyId).displayName
      let fontFamilyList = this.fontFamilyList.filter(
        font => font.displayName.indexOf(displayName) > -1 || displayName.indexOf(font.displayName) > -1
      )
      this.textBatchStyle.fontFamily = fontFamilyList
        .find(item => item.styleName == value)
        .fontFamily.replace('fontCssView', '')
      this.textBatchStyle.fontFamilyId = fontFamilyList.find(item => item.styleName == value).id
      this.$emit('textBatchChange', this.textBatchStyle)
    },
    textBatchChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('textBatchChange', this.textBatchStyle)
    },
    textBatchColorChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('textBatchChange', { fontColor: this.textBatchStyle.fontColor })
    },
    textBackBatchChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.$emit('textBatchChange', { backgroundColor: this.textBatchStyle.backgroundColor })
    },
    textStrokeWidthBatchChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.strokeWidth = value
      this.$emit('textBatchChange', { strokeWidth: this.textBatchStyle.strokeWidth })
    },
    textStrokeColorBatchChange() {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      if (this.textBatchStyle.strokeColor == '#ffffff') {
        this.textBatchStyle.strokeWidth = 4
        this.$emit('textBatchChange', {
          strokeColor: this.textBatchStyle.strokeColor,
          strokeWidth: this.textBatchStyle.strokeWidth
        })
      } else if (this.textBatchStyle.strokeColor == '#000000') {
        this.textBatchStyle.strokeWidth = 3
        this.$emit('textBatchChange', {
          strokeColor: this.textBatchStyle.strokeColor,
          strokeWidth: this.textBatchStyle.strokeWidth
        })
      } else {
        this.$emit('textBatchChange', { strokeColor: this.textBatchStyle.strokeColor })
      }
    },
    textLayoutBatchChange(value) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      this.textBatchStyle.textLayout = value
      this.$emit('textBatchChange', { textLayout: this.textBatchStyle.textLayout })
    },
    clearCopyContent() {
      if (!this.copyContent) return
      this.$emit('clearCopyContent')
    },
    colorClick(type) {
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      this.$refs['colorPicker' + type].openPanel()
    },
    handledChange(value) {
      this.$emit('changeShowImgListWidth', value)
    },
    sliderHandledChange(value) {
      this.sliderValue = value
      let v = value + '%'
      this.$emit('changeShowImgListWidth', v)
    }
  }
}
</script>

<style lang="less" scoped>
.select-icon {
  color: var(--theme-color) !important;
}
/deep/ .ant-select-selection {
  border: none !important;
  box-shadow: none !important;
  &:hover {
    .anticon-down {
      color: var(--theme-color) !important;
    }
  }
}
/deep/ .anticon-down {
  transition: all 0.3s;
}
/deep/ .downLoad-button .anticon-down {
  transition: all 0s;
}
.top-button-list {
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.3s;
  padding-left: 24px;
  > div {
    width: 1340px;
    padding-right: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .top-item-text {
    height: 52px;
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  /deep/ .ant-select-selection:hover {
    background-color: rgba(109, 109, 109, 0.1) !important;
  }
  .top-item {
    height: 52px;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      .top-item-hover {
        background-color: rgba(109, 109, 109, 0.1);
      }
    }
    .down-menu {
      position: absolute;
      top: 14px;
      right: -16px;
      z-index: 1;
      .anticon-down {
        font-size: 12px;
      }
    }
    .top-item-hover {
      position: absolute;
      border-radius: 5px;
      top: 10px;
      left: -5px;
      width: 30px;
      height: 30px;
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);
    }
    .top-item-hover-left {
      position: absolute;
      border-radius: 5px;
      top: -6px;
      left: -5px;
      width: 30px;
      height: 30px;
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);
      &:hover {
        background-color: rgba(109, 109, 109, 0.1);
      }
    }
    .top-item-hover-center {
      position: absolute;
      border-radius: 5px;
      top: -6px;
      left: 25px;
      width: 30px;
      height: 30px;
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);
      &:hover {
        background-color: rgba(109, 109, 109, 0.1);
      }
    }
    .top-item-hover-right {
      position: absolute;
      border-radius: 5px;
      top: -6px;
      left: 55px;
      width: 30px;
      height: 30px;
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);
      &:hover {
        background-color: rgba(109, 109, 109, 0.1);
      }
    }
    cursor: pointer;
    transition: all 0.3s;
    margin-right: 12px;
    .top-item-border {
      height: 16px;
      position: absolute;
      border-left: 1px solid rgba(0, 0, 0, 0.25);
      top: 18px;
      left: -12px;
    }
    &:hover {
      color: var(--theme-color);
    }
    .anticon {
      font-size: 18px;
    }
    .color-change {
      width: 15px;
      height: 52px;
      background: #fff;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      z-index: 1;
      cursor: pointer;
      .color-change-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
      }
      .color-change-back {
        height: 18px;
      }
      .color-change-stroke-text {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
        &:hover {
          -webkit-text-stroke: 1px var(--theme-color) !important;
        }
      }
      .color-change-bottom {
        height: 3px;
      }
      .color-change-bottom-stroke {
        height: 3px;
        border: 1px solid rgba(0, 0, 0, 0.25);
      }
      .color-change-bottom-back {
        width: 80%;
        left: 5px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.25);
        top: 1px;
        height: 3px;
      }
    }
  }
}
.top-slider .ant-slider {
  width: 132px !important;
}
.iconfont {
  font-size: 24px;
}
/deep/ .m-colorPicker .box.open {
  z-index: 100 !important;
  top: 32px;
}
/deep/ .m-colorPicker .box {
  z-index: 100 !important;
  top: 32px;
}
/* @media screen and (max-width: 1480px) {
  .top-item-right {
    display: none !important;
  }
}
@media screen and (max-width: 1100px) {
  .top-item-center {
    display: none !important;
  }
}
@media screen and (max-width: 780px) {
  .top-item-left {
    display: none !important;
  }
} */
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.text-show-option {
  line-height: 32px;
  padding-bottom: 20px;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
  height: 0;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
</style>
